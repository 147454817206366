<template>
    <div>
        <ek-input-select
            :rules="[
                {
                  type: 'required',
                  message: 'اسم الفعالية مطلوب',
                },
              ]"
            name="اسم الفعالية"
            placeholder="اختر اسم الفعالية "
            label="اسم الفعالية"
            textLabel="name"
            valueLabel="id"
            :value="subscripDto.accountId"
            :options="subActivityName"
            @input="updateSelctedNumber"
        ></ek-input-select>

        <ek-input-text
            :value="subNumber"
            readonly
            name="رقم الإشتراك"
            label="رقم الإشتراك"
        ></ek-input-text>

        <ek-date-picker
            :rules="[
                {
                    type: 'required',
                    message: 'مدة الاشتراك مطلوبة',
                },
            ]"
            range
            label="مدة الإشتراك"
            :value="[subscripDto.startSub, subscripDto.endSub].join(' to ')"
            @input="updateSubDate($event)"
            name="subDate"
        />
        <ek-input-select
            :rules="[
                {
                    type: 'required',
                    message: 'الميزات مطلوبة',
                },
            ]"
            multiple
            :options="filterdFeatures"
            v-model="subscripDto.features"
            name="الميزات"
            placeholder="اختر ميزات الفعالية"
            label="ميزات الفعالية"
        ></ek-input-select>
    </div>
</template>
<script>
import { mapState, mapMutations, mapActions } from "vuex";
export default {
    data() {
        return {
            subNumber: "",
        };
    },
    created() {
        this.getActivityName();
    },
    computed: {
        ...mapState({
            subActivityName: (state) => state.subscriptions.subActivityName,
            subscripDto: (state) => state.subscriptions.subscripDto,
            filterdFeatures: (state) => state.subscriptions.filterdFeatures,
        }),
    },
    methods: {
        ...mapMutations(["Update_Sub_Dto_Date", "Update_Select_Num"]),
        ...mapActions(["getActivityName", "getFeatures"]),
        updateSubDate($event) {
            this.Update_Sub_Dto_Date($event);
            this.filterFtrs();
        },
        updateSelctedNumber($event) {
            this.subscripDto.accountId = $event;

            let sub = this.subActivityName.find(
                (item) => item.id == this.subscripDto.accountId
            );
            this.subNumber = sub?.subNumber.toString();

            this.subscripDto.features = [];

            this.filterFtrs();
        },
        filterFtrs() {
            if (
                this.subscripDto.endSub &&
                this.subscripDto.startSub &&
                this.subscripDto.accountId
            )
                this.getFeatures({
                    accountId: this.subscripDto.accountId,
                    featuresType: 4,
                    startSub: this.subscripDto.startSub,
                    endSub: this.subscripDto.endSub,
                });
        },
    },
};
</script>
