<template>
  <div class="subscripe-form">
    <div class="d-flex mr-1">
      <h6 class="mr-1">نوع الحساب:</h6>
      <b-form-radio-group
        v-model="selected"
        :options="options"
        value-field="item"
        text-field="name"
      ></b-form-radio-group>
    </div>
    <ValidationObserver ref="form" @close="reset">
      <component :is="selectedForm"></component>
    </ValidationObserver>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import ActivitySubscripeForm from "./ActivitySubscripeForm.vue";
import UserSubscripeForm from "./UserSubscripeForm.vue";

export default {
  component: {
    ActivitySubscripeForm,
    UserSubscripeForm,
  },

  data() {
    return {
      selected: 1,
      options: [
        { item: 2, name: "فعالية" },
        { item: 1, name: "مستخدم" },
      ],
    };
  },
  created() {
  },
  computed: {
    ...mapState({
      subscripDto: (state) => state.subscriptions.subscripDto,
    }),

    selectedForm() {
      return this.selected == 1 ? UserSubscripeForm : ActivitySubscripeForm;
    },
  },
  methods: {
    ...mapActions(["addAppUserDash", "addActivityDash"]),
    submit() {
      this.$refs["form"].validate().then((valid) => {
        if (valid) {
          if (this.selected == 1)
            this.addAppUserDash({
              accountId: this.subscripDto.accountId,
              startSub: this.subscripDto.startSub,
              endSub: this.subscripDto.endSub,
              features: this.subscripDto.features,
            });
          else
            this.addActivityDash({
              accountId: this.subscripDto.accountId,
              startSub: this.subscripDto.startSub,
              endSub: this.subscripDto.endSub,
              features: this.subscripDto.features,
            });
            this.$emit("submitForm")
        }
      });
    },
    reset() {
      this.$refs.form.reset();
      this.$store.commit("Reset_Subscrip_Dto");
    },
  },
  watch: {
    selected() {
      this.$store.commit("Reset_Subscrip_Dto");
    },
  },
};
</script>
