<template>
    <div>
        <ek-input-select
            :rules="[
                {
                  type: 'required',
                  message: 'اسم المستخدم مطلوب',
                },
              ]"
            name="اسم المستخدم"
            placeholder="اختر اسم المستخدم "
            label="اسم المستخدم"
            textLabel="name"
            valueLabel="id"
            :options="subAppUserName"
            :value="subscripDto.accountId"
            @input="updateSelctedNumber"
        ></ek-input-select>
        <ek-input-text
            :value="subNumber"
            readonly
            name="رقم الإشتراك"
            label="رقم الإشتراك"
        ></ek-input-text>

        <ek-date-picker
            :rules="[
                {
                    type: 'required',
                    message: 'مدة الاشتراك مطلوبة',
                },
            ]"
            range
            label="مدة الإشتراك"
            @input="updateSubDate($event)"
            name="subDate"
            :value="[subscripDto.startSub, subscripDto.endSub].join(' to ')"
        />
        <ek-input-select
            :rules="[
                {
                    type: 'required',
                    message: 'الميزات مطلوبة',
                },
            ]"
            multiple
            :options="filterdFeatures"
            v-model="subscripDto.features"
            name="الميزات"
            placeholder="اختر ميزات المستخدم"
            label="ميزات المستخدم"
        ></ek-input-select>
    </div>
</template>
<script>
import { mapState, mapMutations, mapActions } from "vuex";
export default {
    data() {
        return {
            subNumber: "",
        };
    },
    created() {
        this.getAppUserName();
    },
    computed: {
        ...mapState({
            subAppUserName: (state) => state.subscriptions.subAppUserName,
            subscripDto: (state) => state.subscriptions.subscripDto,
            filterdFeatures: (state) => state.subscriptions.filterdFeatures,
        }),
    },
    methods: {
        ...mapMutations(["Update_Sub_Dto_Date", "Update_Select_Num"]),
        ...mapActions(["getAppUserName", "getFeatures"]),
        filterFtrs() {
            if (
                this.subscripDto.endSub &&
                this.subscripDto.startSub &&
                this.subscripDto.accountId
            )
                this.getFeatures({
                    accountId: this.subscripDto.accountId,
                    featuresType: 2,
                    startSub: this.subscripDto.startSub,
                    endSub: this.subscripDto.endSub,
                });
        },
        updateSubDate($event) {
            this.Update_Sub_Dto_Date($event);
            this.filterFtrs();
        },
        updateSelctedNumber($event) {
            this.subscripDto.accountId = $event;
            this.subscripDto.features = [];
            let sub = this.subAppUserName.find(
                (item) => item.id == this.subscripDto.accountId
            );
            this.subNumber = sub?.subNumber.toString();
            this.filterFtrs();
        },
    },
};
</script>
