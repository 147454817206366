<template>
  <div>
    <ek-dialog
      ref="addDialog"
      title="اشتراك جديد"
      size="md"
      placeholder="ابحث عن اشتراك معين"
      btnText="اشتراك جديد"
      @ok="submitForm"
      @close="resetForm"
      @search="setSearch"
    >
      <template #body>
        <subscrip-form class="w-100" ref="form" @submitForm="test"> </subscrip-form>
      </template>
    </ek-dialog>
  </div>
</template>

<script>
import SubscripForm from "./SubscripForm.vue";

export default {
  components: {
    SubscripForm,
  },
  computed: {
    
  },
  data: () => ({}),
  methods: {
    test() {
        this.$refs.addDialog.close()
    },
    submitForm() {
      this.$refs.form.submit();
    },
    resetForm() {
      this.$refs.form.reset();
    },
    setSearch(query) {
      this.$store.commit("Set_Search_Dto", {
        keys: ["accountName","userName", "startSub", "endSub"],
        query,
      });
    },
  },
};
</script>
